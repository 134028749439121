import $ from "jquery";

function debounce(func, timeout = 300) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const result = (data) => {
  $(".searchModal__results").empty();
  $(".searchModal__no-results").hide();
  // Filter products only
  data = data.filter((i) => i.where_find === "product");

  if (!data.length) {
    $(".searchModal__no-results").show();
    return;
  }

  const html = data.reduce((acc, row) => {
    acc +=
      "" +
      '<div class="search-result">' +
      '   <a class="search-result__category" href="' +
      row.category_href +
      '">' +
      row.category_name +
      "</a>" +
      '   <a class="search-result__product" href="' +
      row.href +
      '">' +
      '       <div class="search-result__img">' +
      '           <img src="' +
      row.image +
      '" alt="' +
      row.name +
      '"/>' +
      "       </div>" +
      '       <div class="search-result__info">' +
      '           <div class="search-result__name">' +
      row.name +
      "</div>" +
      '           <div class="search-result__price">' +
      row.price +
      "</div>" +
      "       </div>" +
      "   </a>" +
      "</div>";
    return acc;
  }, "");

  $(".searchModal__results").append($(html));
};

(async () => {
  $(".js-search-form").each(function () {
    $(this).on("click", function (e) {
      e.preventDefault();
      $(".searchModal").show();
      $(".searchModal__control").css("opacity", 0);
      $(".searchModal__inner").css("top", 200);
      $(".searchModal__control").animate({ opacity: 1 }, 200);
      $(".searchModal__inner").animate({ top: 0 }, 400);
    });
  });

  $(".searchModal__close").click(function (e) {
    e.preventDefault();
    $(".searchModal__inner").animate({ top: -200 }, 400);
    $(".searchModal__control").animate({ opacity: 1 }, 200);
    setTimeout(function () {
      $(".searchModal").hide();
    }, 300);
  });

  $(".searchModal__input").on(
    "input",
    debounce(function () {
      const keyword = $(this).val();
      // min length
      if (keyword.length < 4) return;

      // abort previous requests
      // if (_this.#xhr?.readyState === 1) _this.#xhr.abort();

      const route = "extension/module/d_ajax_search/searchresults";
      const xhr = $.getJSON(
        "index.php",
        {
          keyword,
          route,
        },
        (data, status) => {
          if ("success" === status) {
            result(data);
          }
        },
      );
    }),
  );

  $(".dropdown-toggle").click(function (e) {
    e.preventDefault();
    $(".lang-selector").toggleClass("open");
  });
})();
